import PageRequest from '../../../../pagination-helper/lib/PageRequest';
import SortingModelT from '../../../../common/models/SortingModelT';
import EnumSortingEndpoints from '../../../../analytic-endpoints/i-services/models/EnumSortingEndpoints';

export default class EndpointsRequest  {
	ProjectLogGroupId?: number;
	FromDate: Date;
	ToDate: Date;
	Page: PageRequest;
	SortingModel: SortingModelT<EnumSortingEndpoints>;
	constructor(obj?: Partial<EndpointsRequest>) {
		if (obj) {
			Object.assign(this, obj);
		}
	}
}
